import React, { useState } from 'react';
import { Vector3, HemisphericLight, MeshBuilder, ArcRotateCamera, Color3, PointerEventTypes, Animation, SceneLoader, ParticleSystem, Texture, MeshParticleEmitter, Color4, Plane, MirrorTexture, Scene} from '@babylonjs/core';
import SceneComponent from './SceneComponent';
import { AdvancedDynamicTexture, Container, TextBlock } from '@babylonjs/gui';
import { GridMaterial } from '@babylonjs/materials'
import '@babylonjs/loaders'
import { createMuiTheme, CssBaseline, Grid, responsiveFontSizes, Tab, Tabs, ThemeProvider, Typography, withStyles } from '@material-ui/core';
import MontserratAlternates from '../Fuentes/MontserratAlternates-Thin.ttf'
import FormularioContacto from '../ComponentesAuxiliares/FormularioContacto'




const onSceneReady = scene => {

  const canvas = scene.getEngine().getRenderingCanvas();
  var camera = new ArcRotateCamera("Camera", Math.PI / 2, Math.PI / 2, 2, Vector3.Zero(), scene);
  camera.attachControl(canvas, true);
  var light = new HemisphericLight("light1", new Vector3(-180,-120,-280), scene);
  var light2 = new HemisphericLight("light1", new Vector3(240,-100,240), scene);

  scene.clearColor = new Color3(0/255, 16/255, 23/255);
  scene.ambientColor = new Color3(1,0,0)
 


  SceneLoader.ImportMesh(null, "/", 'combinados1.gltf', scene, function (meshes) {
    // **********************************************************************
    // CEREBRO
    let cerebro = scene.getMeshByName("cerebro");
    let mat11 = new GridMaterial()
    mat11.opacity = .99;
    mat11.majorUnitFrequency = 3.5
    mat11.minorUnitVisibility = .5
    cerebro.material = mat11


    var particleSystem = new ParticleSystem("particles", 150, scene);
    particleSystem.particleTexture = new Texture("/luz4.png", scene);
    var meshEmitter = new MeshParticleEmitter(cerebro);
    particleSystem.particleEmitterType = meshEmitter;
    particleSystem.emitter= cerebro;
    particleSystem.colorDead = new Color4(1, 1, 1, 0.0);
    
    particleSystem.minSize = 18;
    particleSystem.maxSize = 23;
    particleSystem.minLifeTime = 4;
    particleSystem.maxLifeTime = 6;
    particleSystem.emitRate = 3;
    meshEmitter.useMeshNormalsForDirection = false;
    meshEmitter.direction1 = new Vector3(-.3, -.3, -.3);
    meshEmitter.direction2 = new Vector3(.3, .3, .3);
    particleSystem.addAngularSpeedGradient(0, 0.5, 0.2);
    particleSystem.addAngularSpeedGradient(1.0, 2, 2);
    particleSystem.blendMode = ParticleSystem.BLENDMODE_STANDARD

    particleSystem.start();



    // **********************************************************************
    // CAMARA
    camera.setPosition(new Vector3(260, 55, 260)); 
    camera.lowerRadiusLimit = 355 // how far in can you zoom
    camera.upperRadiusLimit = 355 // how far out can you zoom
    if(window.devicePixelRatio>1){
      camera.lowerRadiusLimit = 385 // how far in can you zoom
      camera.upperRadiusLimit = 385 // how far out can you zoom
    }
    camera.lowerBetaLimit = 1.2
    camera.upperBetaLimit = 1.45
    camera.angularSensibilityX = 5000
    camera.angularSensibilityY = 5000
    let target = new Vector3(.01,-25,.01)
    camera.setTarget(target);
    



    // DATA ENGINEER
    camera.alpha = .85
    camera.beta = 1.3
    light2.intensity = 9;
    // CAPACITACIONES
    // camera.alpha = .035
    // camera.beta = 1.43 
    // light.intensity = 0;
    // BIG DATA
    // camera.alpha = -.75
    // camera.beta = 1.37
    // INVERSIONES
    // camera.alpha = -2.2
    // camera.beta = 1.37
    // light.intensity = 5;
    // GRAFICOS
    // camera.alpha = 3.25
    // camera.beta = 1.4
    // light.intensity = 20;
    // BOTELLA DS
    // camera.alpha = 2.25
    // camera.beta = 1.335
    // light2.intensity = 5250;



    // EMISOR PARTICULAS BOTELLA
    var particleSystem2 = new ParticleSystem("particles", 150, scene);
    particleSystem2.emitter = new Vector3(-125, 22, 232) 
    particleSystem2.particleTexture = new Texture("/cubo.png", scene);
    particleSystem2.color1 = new Color4(0.7, 0.9, 1.0, 1.0);
    particleSystem2.color2 = new Color4(0.2, 0.5, 1.0, 1.0);
    particleSystem2.colorDead = new Color4(0, 0, 0.2, 0.0);
    particleSystem2.createConeEmitter(15, Math.PI / 3);
    
    particleSystem2.minSize = 1;
    particleSystem2.maxSize = 4;
    particleSystem2.emitRate = 30;
    particleSystem2.blendMode = ParticleSystem.BLENDMODE_STANDARD


    particleSystem2.start();    



    let disco = scene.getMeshByName("disco");
    disco.visibility = 0;
  
    
    

    // ******  REFLEJOS  ******
    let mirror = scene.getMeshByName("Disc");
    mirror.scaling = new Vector3(100.0, 0.01, 100.0);
    let profesor = scene.getMeshByName("monedas");
    let pizarra = scene.getMeshByName("flecha");
    let barras = scene.getMeshByName("barras");
    // let persona = scene.getMeshByName("estudiantes");
    // let muebles = scene.getMeshByName("muebles");
    // console.log(profesor)

    mirror.material.reflectionTexture = new MirrorTexture("mirror", {ratio: 0.5}, scene, true);
    mirror.material.reflectionTexture.mirrorPlane = new Plane(.0, -1, 0, 0);
    mirror.material.reflectionTexture.renderList = [cerebro, profesor, pizarra, barras];
    // mirror.material.reflectionTexture.level = 1.5;
    mirror.material.reflectionTexture.adaptiveBlurKernel = 32;


    // Fog
    scene.fogMode = Scene.FOGMODE_LINEAR;
    scene.fogStart = 200;
    scene.fogEnd = 400;
    scene.fogColor = new Color3(0/255, 16/255, 23/255);



    // **************************************************************************
    // TEXTOS

    let crearTexto = (position,rotacion,texto,letra,tamano)=>{
      let plane = MeshBuilder.CreatePlane(`plane`, {height: tamano/(1+(window.devicePixelRatio/20)), width:tamano/(1+(window.devicePixelRatio/20))}, scene);
      let advancedTexture = AdvancedDynamicTexture.CreateForMesh(plane);
      plane.position = new Vector3(position[0],position[1],position[2])
      plane.rotation = new Vector3(0, rotacion, 0);
      let text = new TextBlock();
      text.text = texto
      text.color = "hsla(250,50%,100%,100%)";
      text.fontSize = letra;
      text.fontFamily = 'Montserrat'
      text.fontWeight = 200
      if(window.devicePixelRatio>1){
        text.fontWeight = 300
      }
      text.textWrapping = true
      let cont2 = new Container()
      cont2.background = "hsla(250,50%,100%,.5%)"
      advancedTexture.addControl(cont2);
      cont2.addControl(text)
      advancedTexture.markAsDirty()
    }  

    // DATA SCIENCE
    crearTexto([-105, 70, 210], 2.5, 'DATA SCIENCE', 75, 110)
    crearTexto([-125, 24, 190], 2.4, 'Utilizamos la información del pasado para determinar los patrones de comportamiento de los datos, y así prever conductas a futuro.', 95, 46)
    crearTexto([-125, 50, 190], 2.4, 'PREDICTIVE ANALYTICS', 70, 76)
    crearTexto([-75, 25, 235], 2.8,
       `*Estimación de la demanda
*Segmentación de clientes en base a su perfil de compra
*Mantenimiento predictivo
*Modelos decisorios inteligentes`,
        88, 50)

    // DATA ENGINEERING
    crearTexto([140, 69, 200], -2.4, 'DATA ENGINEERING', 75, 110)
    crearTexto([170, 35, 180], -2.4, 'Es clave que la información que uses sea apropiada, la mayoría de las fallas en la toma de decisiones se producen por no tener suficiente informacion o informacion inadecuada.', 80, 50)
    crearTexto([100, 35, 230], -2.6, 'Creamos canales para preprocesar y limpiar las distintias fuentes de datos, y así dejarlos disponibles para su posterior análisis.', 80, 50)
    
    // CAPACITACIÓN & MENTORING
    crearTexto([265, 47, 7], -1.5, 'CAPACITACIÓN & MENTORING', 75, 110)
    crearTexto([265, 25, -35], -1.5, 'Capacitamos y le ayudammos a construir su equipo de análisis de datos.', 75, 50)
    crearTexto([265, 17, 35], -1.5, 'Trabajamos a la par de nuestros clientes a fin de lograr que las personas encargadas de trabajar con los datos tengan el know how necesario para una implementación exitosa.', 75, 50)
    
    // DATA MANAGEMENT
    crearTexto([140, 67, -198], -.9, 'DATA MANAGEMENT', 75, 110)
    crearTexto([170, 50, -145], -1, 'BIG DATA', 70, 76)
    crearTexto([170, 35, -145], -1, 'Implementación de arquitecturas de datos distribuidas, con datos SQL, NoSQL o data streaming.', 75, 60)
    crearTexto([115, 50, -235], -.5, 'DATA WAREHOUSE', 70, 76)
    crearTexto([115, 24, -235], -.5, 'Centralizamos los datos de distintos sistemas en un solo lugar,  reduciendo la necesidad de intervenciones manuales y permitiendo el análisis de tendencias históricas para potenciar su negocio.', 75, 48)
    
     // ANÁLISIS DE INVERSIONES
     crearTexto([-100, 55, -220], .65, 'ANÁLISIS DE INVERSIONES', 75, 110)
     crearTexto([-140, 20, -185], .7, 'El impacto de la economía en un negocio es innegable. Sin embargo calcular este impacto muchas veces no es tan sencillo, por la cantidad de variables involucradas y costos de oportunidad.', 75, 60)
     crearTexto([-65, 20, -250], .5, `
*Manejo de carteras.
*Planeación de estrategias por escenarios.
*Estudios de factibilidad operativa.
*Modelado conceptual.
     `, 75, 48)

    // DATA VISUALISATION
    crearTexto([-250, 65, -20], 1.4, 'DATA VISUALISATION', 75, 110)
    crearTexto([-250, 45, 40], 1.5, 'DASHBOARDS', 70, 70)
    crearTexto([-250, 20, 40], 1.5, 'Unificamos los indicadores claves de rendimiento (KPI) y sus respectivos parámetros o umbrales, en forma de gráficos, permitiendo monitorear la situación del negocio en tiempo real.', 75, 48)
    crearTexto([-240, 55, -60], 1.4, 'REPORTES OPERACIONALES', 70, 70)
    crearTexto([-240, 44, -60], 1.4, 'Brindando información clara y precisa para la toma correcta de decisiones.', 75, 48)
    crearTexto([-240, 28, -60], 1.4, 'SISTEMAS DE ALERTAS', 70, 70)
    crearTexto([-240, 12, -60], 1.4, 'Es una solución que permite a las empresas reaccionar rapidamente a eventos programados, ya sea en una forma reactiva o preventiva.', 75, 48)

    // console.log(camera)



    // ********************************************************************
    // EVENTOS DE CAMBIO DE LUCES
    scene.onPointerObservable.add((pointerInfo) => {
      if(pointerInfo.type === PointerEventTypes.POINTERUP){
        // console.log('camera.alpha:', camera.alpha)
        let alpha = camera.alpha % (2*Math.PI)
        if(alpha < 0){
          alpha = alpha + (2*Math.PI)
        }
        // console.log('alpha:',alpha)
        if(alpha < 1 && alpha > .7  ){
          // DATA ENGINEER
          // console.log('DATA ENGINEER')
          let animacion1 = new Animation("myAnimation", "intensity", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)
          let keys1 = [{frame: 0,value: light2.intensity}, {frame: 30, value: 9}]; 
          animacion1.setKeys(keys1);
          light2.animations = [];
          light2.animations.push(animacion1);
          scene.beginAnimation(light2, 0, 30, false);
          
          let animacion2 = new Animation("myAnimation", "intensity", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)
          let keys2 = [{frame: 0,value: light2.intensity}, {frame: 30, value: 1}]; 
          animacion2.setKeys(keys2);
          light.animations = [];
          light.animations.push(animacion2);
          scene.beginAnimation(light, 0, 30, false);
        }else if(alpha < .7 && alpha > .0  ){
          // CAPACITACIONES
          // console.log('CAPACITACIONES')
          let animacion1 = new Animation("myAnimation", "intensity", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)
          let keys1 = [{frame: 0,value: light2.intensity}, {frame: 30, value: 1}]; 
          animacion1.setKeys(keys1);
          light2.animations = [];
          light2.animations.push(animacion1);
          scene.beginAnimation(light2, 0, 30, false);
          
          let animacion2 = new Animation("myAnimation", "intensity", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)
          let keys2 = [{frame: 0,value: light2.intensity}, {frame: 30, value: 0}]; 
          animacion2.setKeys(keys2);
          light.animations = [];
          light.animations.push(animacion2);
          scene.beginAnimation(light, 0, 30, false);
        }else if(alpha < 5.7 && alpha > 5.4  ){
          // BIG DATA
          // console.log('BIG DATA')
          let animacion1 = new Animation("myAnimation", "intensity", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)
          let keys1 = [{frame: 0,value: light2.intensity}, {frame: 30, value: 1}]; 
          animacion1.setKeys(keys1);
          light2.animations = [];
          light2.animations.push(animacion1);
          scene.beginAnimation(light2, 0, 30, false);
          
          let animacion2 = new Animation("myAnimation", "intensity", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)
          let keys2 = [{frame: 0,value: light2.intensity}, {frame: 30, value: 1}]; 
          animacion2.setKeys(keys2);
          light.animations = [];
          light.animations.push(animacion2);
          scene.beginAnimation(light, 0, 30, false);
        }else if(alpha < 4.25 && alpha > 3.9 ){
          // INVERSIONES
          // console.log('INVERSIONES')
          let animacion1 = new Animation("myAnimation", "intensity", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)
          let keys1 = [{frame: 0,value: light2.intensity}, {frame: 30, value: 1}]; 
          animacion1.setKeys(keys1);
          light2.animations = [];
          light2.animations.push(animacion1);
          scene.beginAnimation(light2, 0, 30, false);
          
          let animacion2 = new Animation("myAnimation", "intensity", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)
          let keys2 = [{frame: 0,value: light2.intensity}, {frame: 30, value: 5}]; 
          animacion2.setKeys(keys2);
          light.animations = [];
          light.animations.push(animacion2);
          scene.beginAnimation(light, 0, 30, false);
        }else if(alpha < 3.4 && alpha > 3.1  ){
          // DATA VISUALISATION
          let animacion1 = new Animation("myAnimation", "intensity", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)
          let keys1 = [{frame: 0,value: light2.intensity}, {frame: 30, value: 1}]; 
          animacion1.setKeys(keys1);
          light2.animations = [];
          light2.animations.push(animacion1);
          scene.beginAnimation(light2, 0, 30, false);
          
          let animacion2 = new Animation("myAnimation", "intensity", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)
          let keys2 = [{frame: 0,value: light2.intensity}, {frame: 30, value: 20}]; 
          animacion2.setKeys(keys2);
          light.animations = [];
          light.animations.push(animacion2);
          scene.beginAnimation(light, 0, 30, false);
        }else if(alpha < 2.4 && alpha > 2.1  ){
          // DATA SCIENCE
          let animacion1 = new Animation("myAnimation", "intensity", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)
          let keys1 = [{frame: 0,value: light2.intensity}, {frame: 30, value: 5250}]; 
          animacion1.setKeys(keys1);
          light2.animations = [];
          light2.animations.push(animacion1);
          scene.beginAnimation(light2, 0, 30, false);
          
          let animacion2 = new Animation("myAnimation", "intensity", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT)
          let keys2 = [{frame: 0,value: light2.intensity}, {frame: 30, value: 1}]; 
          animacion2.setKeys(keys2);
          light.animations = [];
          light.animations.push(animacion2);
          scene.beginAnimation(light, 0, 30, false);
        }
      }
    })
  })	
}








const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);






export default () => {
  

  const montserrata = {
    fontFamily: 'Montserrat-a',
    src: `url(${MontserratAlternates})format('truetype')`
  }
  let theme = createMuiTheme({
    typography:{
      fontFamily: 'Montserrat-a'
    },
    overrides:{
      MuiCssBaseline:{
        '@global':{
          '@font-face': [montserrata]
        }
      }
    }
  })
  theme = responsiveFontSizes(theme);


  const [value, setValue] = useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(newValue === 3){ //osea que sea el 4to item que es el de contacto
      // abro el modal
      abrirformulario()
    }
  };


  const [open, setOpen] = useState(false);
  const abrirformulario = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };





  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{ position:"absolute",zIndex:"8888",color:"white", margin: '15px'}} >
        <Grid container alignItems="center" spacing={1} >
          <Grid item xs={2} md={1}>
              <img src="icon.png" alt="" width="100%" />
          </Grid>
          <Grid item >
            <Typography variant="h4" style={{fontFamily:'MuseoModerno'}}>
              Lynxy <span style={{color:"rgb(138,213,249)"}}>.AR</span>
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div style={{ position:"absolute",zIndex:"8888", left: '10%', bottom:'5%', width: "80%"}} >
        <StyledTabs variant="scrollable"  value={value} onChange={handleChange} aria-label="styled tabs example">
          <StyledTab label="Desarrollo de Software" />
          <StyledTab label="Data Analytics" />
          <StyledTab label="IoT" />
          <StyledTab label="Contacto" />
        </StyledTabs>
      </div>
      <SceneComponent onSceneReady={onSceneReady} id='my-canvas' />

      <FormularioContacto open={open} onClose={handleClose} />

    </ThemeProvider>
)}

