import { Engine, Scene } from '@babylonjs/core'
import React, { useEffect, useRef } from 'react'

export default (props) => {


    //   var canvas = document.getElementById("renderCanvas"); // Get the canvas element
  //   var engine = new Engine(canvas, true); // Generate the BABYLON 3D engine

  //   var createScene = function () {
  //       // Create the scene space
  //       var scene = new Scene(engine);
  //       return scene;
  //   };
  //   var scene = createScene(); //Call the createScene function

  //   // Register a render loop to repeatedly render the scene
  //   engine.runRenderLoop(function () {
  //           scene.render();
  //   });

  //   // Watch for browser/canvas resize events
  //   // window.addEventListener("resize", function () {
  //   //         engine.resize();
  //   // });



    const reactCanvas = useRef(null);
    const { antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady, ...rest } = props;



    useEffect(() => {
        
        if (reactCanvas.current) {
            const engine = new Engine(reactCanvas.current, antialias, engineOptions, adaptToDeviceRatio);
            const scene = new Scene(engine, sceneOptions);
            if (scene.isReady()) {
                props.onSceneReady(scene)
            } else {
                scene.onReadyObservable.addOnce(scene => props.onSceneReady(scene));
            }

            engine.runRenderLoop(() => {
                if (typeof onRender === 'function') {
                    onRender(scene);
                }
                scene.render();
            })

            const resize = () => {
                // la unica diferencia q encuentro en todo el codigo es que antes aca hacia:
                // engine.resize();
                scene.getEngine().resize();
            }

            if (window) {
                window.addEventListener('resize', resize);
            }
            // recordar q el return de useEffect es para borrar los eventos cuando se desmonte
            return () => {
                scene.getEngine().dispose();

                if (window) {
                    window.removeEventListener('resize', resize);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reactCanvas])

    return (
        <canvas ref={reactCanvas} {...rest}  />
    );
}