import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, createMuiTheme, CssBaseline, Fade, Grid, Grow, responsiveFontSizes, ThemeProvider, Typography, Zoom } from '@material-ui/core';
import MontserratAlternates from '../Fuentes/MontserratAlternates-Thin.ttf'
import Componente5 from './Componente5';



export default function Home(){

    const montserrata = {
        fontFamily: 'Montserrat-a',
        src: `url(${MontserratAlternates})format('truetype')`
      }
      let theme = createMuiTheme({
        typography:{
          fontFamily: 'Montserrat-a'
        },
        overrides:{
          MuiCssBaseline:{
            '@global':{
              '@font-face': [montserrata]
            }
          }
        }
      })
      theme = responsiveFontSizes(theme);
    

      const [pagina1,setPagina1] = useState(true)
      const [pagina2,setPagina2] = useState(false)
      const [pagina3,setPagina3] = useState(false)
      const [paginaClick,setPaginaClick] = useState(false)
    
      const [pagina1d,setPagina1d] = useState(true)
      const [pagina2d,setPagina2d] = useState(false)
      const [pagina3d,setPagina3d] = useState(false)
      const [paginaClickd,setPaginaClickd] = useState(false)
    
    
      const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
    
      async function animaciones(){
        await sleep(5000)
        setPagina1(false)
        await sleep(700)
        setPagina1d(false)
        setPaginaClickd(true)
        setPaginaClick(true)

      }

      useEffect(()=>{
        animaciones()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])
    
      let history = useHistory()
      async function handleClick(){
        if(pagina2){
          setPagina2(false)
          await sleep(700)
          setPagina2d(false)
          setPagina3d(true)
          setPagina3(true)
        }else if(pagina3){
          setPagina3(false)
          await sleep(700)
          setPagina3d(false)
          history.push('/data-analytics');
        }else if(paginaClick){
          setPaginaClick(false)
          await sleep(600)
          setPaginaClickd(false)
          setPagina2d(true)
          setPagina2(true)
        }
      }
    

    return(
    <ThemeProvider theme={theme}>
    <CssBaseline />
    

        <div style={{ position:"absolute",fontSize:"50px",zIndex:"9999",color:"white",backgroundColor:"rgb(0,16,23)",width:"100%", height:"100vh"}} onClick={handleClick} >
        {/* MARCA Y SLOGAN */}
        <Fade in={pagina1} style={{display: pagina1d?'':'None'}} timeout={{enter:1500, appear:0, exit:500}}>
            <Box textAlign="center" mt="4.5em">
            <Typography mb="200" variant="h1" style={{fontFamily:'MuseoModerno'}}>
                Lynxy <span style={{color:"rgb(138,213,249)"}}>.AR</span>
            </Typography>
            </Box>
        </Fade>
        <Fade in={pagina1} timeout={{enter:2500, appear:0, exit:500}} style={{ transitionDelay: pagina1 ? '1500ms' : '0ms', display: pagina1d?'':'None'}}>
            <Box textAlign="center" mt=".5em">
            <Typography variant="h3" >
                Better with data
            </Typography>
            </Box>
        </Fade>

        <Fade in={paginaClick} timeout={{enter:1000, appear:0, exit:500}} style={{ display: paginaClickd?'':'None'}}>
            <Box textAlign="center" mt="3.5em">
            <Typography variant="h3" >
                (Haga click para avanzar...)
            </Typography>
            </Box>
        </Fade>
        
        {/* QUE HACEMOS Y OBJETIVO */}
        <Grid container  direction="row" justify="space-around" alignItems="center" style={{height: '80%', display: pagina2d?'':'None' }}>
            <Grid item xs={10} md={5} >
            <Zoom in={pagina2} timeout={{enter:1000, appear:0, exit:500}}>
                <Box textAlign="center" >
                <Typography variant="h4" >
                    Somos una empresa especializada en el desarrollo de software, implementación de soluciones de Data Analytics y desarrollo de proyectos de IoT.
                </Typography>
                </Box>
            </Zoom>
            </Grid>
            <Grid item xs={10} md={5} >
            <Zoom in={pagina2} timeout={{enter:1000, appear:0, exit:500}} style={{ transitionDelay: pagina2 ? '1000ms' : '0ms' }}>
                <Box textAlign="center" >
                <Typography variant="h4" >
                    Nuestro objetivo es ayudarlo a reinventar su proceso productivo y llevar su negocio al siguiente nivel.
                </Typography>
                </Box>
            </Zoom>
            </Grid>
        </Grid>

        
        {/* PAGINA 3 (servicios) */}
        <Grid container  direction="row" justify="space-around" alignItems="center" style={{height: '80%', display: pagina3d?'':'None'}}>
            <Grid item xs={10} md={5} >
            <Grow in={pagina3} timeout={{enter:1000, appear:0, exit:500}} style={{ transformOrigin: '0 0 0' }} >
                <Box textAlign="center" mt={4}>
                <Box mb={2}>
                <Typography variant="h3" >
                    SOLUCIONES A MEDIDA
                </Typography>
                </Box>
                <Typography variant="h4" >
                    En Lynxy.AR brindamos un servicio adaptado a cada cliente, creando soluciones que le ayuden en la toma de decisiones y potencien la rentabilidad de su negocio.
                </Typography>
                </Box>
            </Grow>
            </Grid>
            <Grid item xs={10} md={5} >
            <Grow in={pagina3} timeout={{enter:2000, appear:0, exit:500}} style={{ transitionDelay: pagina3 ? '1000ms' : '0ms', transformOrigin: '0 0 0' }} >
                <Box textAlign="center" mt={4}>
                <Box mb={2}>
                <Typography variant="h3" >
                    SIEMPRE CON USTED
                </Typography>
                </Box>
                <Typography variant="h4" >
                    Acceda a sus datos desde cualquier dispositivo y lugar.
                </Typography>
                </Box>
            </Grow>
            </Grid>
        </Grid>


        {/* PAGINA 4 */}
        {/* <Fade in={pagina4} timeout={{enter:2500, appear:0, exit:2000}} style={{ transitionDelay: pagina4 ? '1500ms' : '0ms', display: pagina4d?'':'None'}}>
            <Box textAlign="center" mt="3.5em"  >
            <Typography variant="h3" >
                DESCUBRÍ NUESTROS SERVICIOS...
            </Typography>
            </Box>
        </Fade> */}

        </div>

    
    
    <Componente5 />
    </ThemeProvider>
    )
}