import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

// import logo from './logo.svg';
import './App.css';
// import Componente1 from './Componentes/Componente1';
// import Componente2 from './Componentes/Componente2';
// import Componente3 from './Componentes/Componente3';
import Componente5 from './Componentes/Componente5';
import Home from './Componentes/Home';


function App() {
  
  
  return (
    
    <Router>
      <Switch>
        <Route path="/data-analytics">
          <Componente5 />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  
    
  );
}

export default App;
