import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, makeStyles, Snackbar, TextField, withStyles } from '@material-ui/core';





const useStyles1 = makeStyles((theme) => ({
  root: {
    color:'white',    
  }}));


const InputPersonalizado = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#555',
      },
      '&:hover fieldset': {
        borderColor: '#ccc',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ccc',
        backgroundColor: '#00000050',
      },
    },
  },
})(TextField);




export default function FormularioContacto(props) {
  const { onClose, open } = props;
  let [inputs,setinputs] = useState({})
  let [snack,setsnack] = useState(false)
  let [snack2,setsnack2] = useState(false)

  const handleClose = () => {
    onClose();
  };

  let actualizarInputs = (e,campo)=>{
    setinputs({
      ...inputs,
      [campo]: e.target.value
    })
  }


  let enviarFormulario = async()=>{
    if(!inputs.consulta || !inputs.contacto){
      setsnack2(true)
      return
    }
    let url = 'https://api.lynxy.ar/contacto'
    let headers = { 'Content-Type':'application/json'}
    let resp = await fetch(url, {method:'POST', headers:headers, body: JSON.stringify(inputs) })
    resp = await resp.json()

    if(resp.resp === 'enviado'){
      setinputs({})
      setsnack(true)
      setTimeout(()=>{
        handleClose()
      },2000)
    }
  }

  // ESTA VARIABLE POR ALGUN MOTIVO TENIA QUE LLAMARSE 'classes' SI O SI..
  const classes = useStyles1();


  return (
    <Dialog maxWidth='sm' fullWidth={true} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} PaperProps={{style:{backgroundColor:'hsla(200,25%,90%,.1)',boxShadow:'3px 7px 15px hsla(200,15%,30%,.9)'}}} style={{backgroundColor: 'hsla(100,0%,0%,.8)', zIndex:99999}}>
      <DialogTitle id="simple-dialog-title" style={{color:'white', textAlign:'center'}} >FORMULARIO DE CONTACTO</DialogTitle>
      <DialogContent>
        <DialogContentText style={{color:'white'}} >
          Dejanos tu consulta y tus datos de contacto y te responderemos a la brevedad.
        </DialogContentText>
        <InputPersonalizado
          variant='outlined'
          required
          value={inputs.contacto}
          onChange={(e)=>actualizarInputs(e,'contacto')}
          InputProps={{ classes, disableUnderline: true }}
          InputLabelProps={{style:{color:'#ccc'}}}
          margin="dense"
          id="name"
          label="Email o Teléfono de contacto"
          fullWidth
        />
        <InputPersonalizado
          variant='outlined'
          required
          value={inputs.consulta}
          onChange={(e)=>actualizarInputs(e,'consulta')}
          style={{marginBottom:'15px'}}
          InputProps={{ classes, disableUnderline: true }}
          InputLabelProps={{style:{color:'#ccc'}}}
          margin="dense"
          // id="name"
          label="Escribe tu consulta"
          multiline
          rows={5}
          fullWidth
        />
        <Button onClick={enviarFormulario} variant='outlined' style={{color:'white', borderColor:'#555'}} >Enviar</Button>
      </DialogContent>  
      <Snackbar open={snack} autoHideDuration={2000} message='Consulta enviada!' onClose={()=>setsnack(false)} ContentProps={{style:{backgroundColor:'hsla(100,80%,30%,.7)'}}} />
      <Snackbar open={snack2} autoHideDuration={1000} message='Completa el formulario!' onClose={()=>setsnack2(false)} ContentProps={{style:{backgroundColor:'hsla(0,80%,30%,.7)'}}} />
    </Dialog>
  );
}